import React, { useEffect, useState } from 'react'

import CtaLogosGov from '../../components/CtaLogosGov'
import CallOrangeIcon from '../../components/CallOrangeIcon'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

function ShowVideo(props) {

  if(props.lang==='ar'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/2oaq_ihItMQ?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='km'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/iB3pFH5R568?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='tw'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/vQKuOW7XvCE?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='fa'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/fHuLtx7Ebbs?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='ko'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/EX8VuyyYhoQ?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='ru'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/T07rD6mpH8Y?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='es'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="Split Apart - Spanish"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/8pQqu_tZ-mw?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='tl'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/jxc3wUsmZ7c?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='hy'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/l8fq7hWXIs4?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else if(props.lang==='vi'){
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        loading="lazy"
        title="ABC's of SUD Campaign"
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/wjS4onBQK8c?rel=0&cc_load_policy=1`}
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>)
  }
  else {
    return (<figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio">
    <div class="wp-block-embed__wrapper">
      <iframe
        width="580"
        height="326"
        src={`https://www.youtube-nocookie.com/embed/T2feYsGKf_A?rel=0&cc_load_policy=1&cc_lang_pref=en&hl=en`}
        title="ABC's of SUD Campaign"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </figure>);
  }

  return null;
} 


const SudVideos = (props) => {
  const [language, setLanguage] = useState(undefined)
  useEffect(() => {
    const langParam =
      props.location &&
      props.location.search &&
      props.location.search.split('=')[1]

    if (!langParam || langParam === '') {
      const lang = window.Weglot && window.Weglot.getCurrentLang()

      const newUrl = `${props.location.protocol}//${props.location.host}${
        props.location.pathname
      }?lang=${lang || 'en'}`
      window.history.pushState({ path: newUrl }, '', newUrl)
      window.location.reload()
    } else {
      setLanguage(langParam)
    }
  }, [])

  useEffect(() => {
    const langParam =
      props.location &&
      props.location.search &&
      props.location.search.split('=')[1]

    if (!langParam || langParam === '') {
      const lang = window.Weglot && window.Weglot.getCurrentLang()

      const newUrl = `${props.location.protocol}//${props.location.host}${
        props.location.pathname
      }?lang=${lang || 'en'}`
      window.history.pushState({ path: newUrl }, '', newUrl)
    }
    setLanguage(langParam)
  }, [])

  return (
    <Layout>
      <Seo title={'SUD VIDEOS'} />
      <div className="bodyPostTemplate postColorTemplate">
        <div className="row bg-mid-hero">
          <div class="col align-self-center">
            <h1
              className="title-post like-h3"
              dangerouslySetInnerHTML={{ __html: 'SUD VIDEOS' }}
            />
          </div>
        </div>

        <div class="row">
          <a class="skip-main" href="#main" role="none">
            Skip to main content
          </a>
          <div className="mt-4 content-post">
          
           <ShowVideo lang={language} />
            
          </div>
        </div>
      </div>
      <CtaLogosGov />
      <CallOrangeIcon />
    </Layout>
  )
}

export default SudVideos
